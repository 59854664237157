import { GiftCategories } from "./Questions"; export const AnswerKey: Array<GiftCategories> = ["Administration", //1
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom",
    "Administration", //20
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom",
    "Administration",
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom",
    "Administration",
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom",
    "Administration",
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom",
    "Administration",
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom",
    "Administration",
    "Apostleship",
    "Craftsmanship",
    "Creative Communication",
    "Discernment",
    "Encouragement",
    "Evangelism",
    // "Faith",
    "Giving",
    "Helps",
    "Hospitality",
    "Intercession",
    "Knowledge",
    "Leadership",
    "Mercy",
    "Prophecy",
    "Shepherding",
    "Teaching",
    "Wisdom"
]