import React from 'react';
import { Countdown } from './Countdown';
import { IQuestion, QDB } from './Questions';
import { RadioScaleAnswers } from './RadioAnswers';

export class Timer{
  public elapsedMS: number = 0;
  public paused: boolean = false;
  constructor(
    public readonly DurationMS: number,
    private onExpireCallback: () => void,
    private onElapsedChange: (elapsed:number) => void){
      window.requestAnimationFrame((time: DOMHighResTimeStamp) => {
        this.previousTimeMS = time;
        window.requestAnimationFrame(this.tick);
      });
  }

  addMilliseconds(deltaMS: number){
    if (this.paused) 
      return;
    const newElapsedMS = this.elapsedMS + deltaMS;
    if (newElapsedMS > this.DurationMS){
        this.onExpireCallback();
        this.elapsedMS = 0;
    } else {
      this.elapsedMS = newElapsedMS;
      this.onElapsedChange(this.elapsedMS);
    }
  }
  private previousTimeMS: number = 0;
  private dead = false;
  tick = (timeMS: DOMHighResTimeStamp) => {
      if (this.dead)
          return;
      const deltaTimeMS = (timeMS - this.previousTimeMS);
      
      this.previousTimeMS = timeMS;
      if (deltaTimeMS > 0){
          this.addMilliseconds(deltaTimeMS);
      }

      window.requestAnimationFrame(this.tick);
  }
  stop(){
      this.dead = true;
  }
}

const TimeToAnswerQuestionMS = 25000;
export class Question extends React.Component<{
  questionIndex: number;
  onNext: (a: string|number) => void
  onPrev: () => void
  onRestart: () => void
}, {
  elapsedMS: number,
  paused: boolean
}> {
  constructor(props: any){
    super(props);
    this.state = {
      elapsedMS: 0,
      paused: false
    }
  }
  private timer?: Timer;
  componentDidMount(){
    this.timer = new Timer(TimeToAnswerQuestionMS, () => {
      this.submitAnswer(1);
    }, (elapsed: number) => {
      this.setState({elapsedMS: elapsed})
    });
  }
  componentWillUnmount(){
    if (this.timer){
      this.timer.stop();
    }
  }
  private submitAnswer(answer: string|number){
    this.props.onNext(answer);
    if (this.timer)
      this.timer.elapsedMS = 0;
  }
  renderAnswers(data: IQuestion){
    return <RadioScaleAnswers
      questionIndex={this.props.questionIndex}
      scale={QDB.Scale}
      confirmAnswer={(answer: number) => {
        this.submitAnswer(answer);
      }}
      labels={QDB.Labels}
    ></RadioScaleAnswers>
  }
  restart(){
    if (window.confirm('Are you sure you want to restart?')){
      this.props.onRestart();
    }
  }
  render() {
    const data = QDB.Questions[this.props.questionIndex];
    if (data == null)
      return <div></div>;
    return <div className="view mobile-flex-v">
      <div className="question-parent">
        {data.question}
      </div>
      <div className="countdown-parent">
        <Countdown
        elapsedMS={this.state.elapsedMS}
        durationMS={this.timer ? this.timer.DurationMS : 1}
        ></Countdown>
      </div>
      <div className="answer-parent">
        {this.renderAnswers(data)}
        <div className="mrg-tb-15">
          <button
            disabled={this.props.questionIndex < 1} 
            className="small"
            onClick={
              () => {
                this.props.onPrev();
              if (this.timer) this.timer.elapsedMS = 0;
              this.setState({elapsedMS: 0})
            }}>
            Previous
          </button>
          <button className="small" type="button" onClick={() => {
              if (this.timer) {
                this.setState({paused: !this.state.paused});
                this.timer.paused = !this.timer.paused;
              }
          }}>
            {this.state.paused ? "Resume" : "Pause"}
          </button>
          <button className="small" type="button" onClick={() => this.restart()}>
            Restart
          </button>
        </div>
      </div>
    </div>
  }
}
