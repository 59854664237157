export interface IQuestion{
    question: string
}
export type GiftCategories = 'Administration'|
'Apostleship'|
'Craftsmanship'|
'Creative Communication'|
'Discernment'|
'Encouragement'|
'Evangelism'|
// 'Faith'|
'Giving'|
'Helps'|
'Hospitality'|
'Intercession'|
'Knowledge'|
'Leadership'|
'Mercy'|
'Prophecy'|
'Shepherding'|
'Teaching'|
'Wisdom';

// these are manually in order! don't change the order or you'll break it
export const AllGiftCategories = ['Administration',
'Apostleship',
'Craftsmanship',
'Creative Communication',
'Discernment',
'Encouragement',
'Evangelism',
// 'Faith',
'Giving',
'Helps',
'Hospitality',
'Intercession',
'Knowledge',
'Leadership',
'Mercy',
'Prophecy',
'Shepherding',
'Teaching',
'Wisdom'];
// const OneToFive = [1, 2, 3, 4, 5];
const ZeroToThree = [0, 1, 2, 3];
// const LabelMostLeast = {1: 'Least', 5: 'Most'};
const LabelZeroThreeTrue = {0: 'Never', 1: 'Once in a while', 2: 'Most of the time', 3: 'Always'};
export class QDB{
    static Scale = ZeroToThree;
    static Labels = LabelZeroThreeTrue;
    static Questions: Array<IQuestion> = [
{question:/**1. */"I am comfortable organizing people, tasks, and events."},
{question:/**2. */"If I saw the opportunity and was aware of the need, I would participate in starting a new church."},
{question:/**3. */"I enjoy working with wood, cloth, paints, metal, glass, or other materials."},
{question:/**4. */"I can challenge people's perspective of God using art, drama, writing, music and/or computer."},
{question:/**5. */"I find it natural to distinguish between spiritual truth and error, good and evil."},
{question:/**6. */"I tend to see the potential in people."},
{question:/**7. */"When necessary or appropriate, I explain my understanding of the Gospel to others."},
// {question:/**8. */"It is easy for me to trust God to answer my prayers."},
{question:/**9. */"I give freely to people in financial need."},
{question:/**10.*/"I enjoy working behind the scenes to help others."},
{question:/**11.*/"My home is a comfortable place to minister to people."},
{question:/**12.*/"I ask others to pray for me and I consistently pray for them."},
{question:/**13.*/"People ask me about my perspective of the Bible."},
{question:/**14.*/"I am comfortable helping others reach their goals."},
{question:/**15.*/"I empathize with people in pain and try to help them heal."},
{question:/**16.*/"I speak in a way that results in conviction and change in others' lives."},
{question:/**17.*/"I enjoy nurturing and caring for others."},
{question:/**18.*/"I can communicate God's word effectively."},
{question:/**19.*/"Others seek me out for advice about spiritual or personal matters."},
{question:/**20.*/"I can carefully, thoroughly, and skillfully manage details"},
{question:/**21.*/"I dream of serving in another country or ethnic community."},
{question:/**22.*/"I have the ability to work with different kinds of tools."},
{question:/**23.*/"Using my artistic skills such as art, drama, music, writing, computer, and/or photography is a joy to me."},
{question:/**24.*/"I have the ability to judge a person's character based upon first impressions."},
{question:/**25.*/"I have the ability to reassure and strengthen those who are disheartened."},
{question:/**26.*/"I tend to look for opportunities to build relationships with non-Christians."},
// {question:/**27.*/"I believe that God will provide, even in difficult times."},
{question:/**28.*/"I give cheerfully to the church so that God's ministry can be realized."},
{question:/**29.*/"I enjoy doing routine tasks that support the ministry of the church."},
{question:/**30.*/"I enjoy meeting new people and helping them feel welcome."},
{question:/**31.*/"I enjoy praying often and seem to know what God wants me to pray for."},
{question:/**32.*/"I find that I sometimes know things I sense came from the Spirit."},
{question:/**33.*/"I have the ability to influence others who are searching for a vision."},
{question:/**34.*/"I support others as they go through painful situations and help bring order to their lives."},
{question:/**35.*/"I feel responsible to confront others with the truth."},
{question:/**36.*/"I have compassion for others and have a desire to help those seeking spiritual growth."},
{question:/**37.*/"I spend time in study so that I can present the truth in a way that will make a difference in others' lives."},
{question:/**38.*/"I have the ability to find simple, practical solutions in the midst of difficulty or turmoil."},
{question:/**39.*/"I clarify goals and develop strategies or plans that help accomplish those goals. "},
{question:/**40.*/"I would participate in a new group or church organization intended for those seeking to become Christians."},
{question:/**41.*/"I enjoy making things for use in the ministry of the church."},
{question:/**42.*/"I have the ability to help others understand God and themselves better through my artistic expression."},
{question:/**43.*/"I see through phoniness or deceit easier than most do."},
{question:/**44.*/"I give hope to others by reminding them of the promises of God."},
{question:/**45.*/"I have the ability to explain my understanding of the Gospel as it applies to another's personal faith needs."},
// {question:/**46.*/"I believe that God will help me accomplish great things in accordance with His dreams for me."},
{question:/**47.*/"I manage my money and resources well in order to give more to ministry."},
{question:/**48.*/"I willingly take on a variety of odd jobs around the church to help others."},
{question:/**49.*/"I believe God directs strangers to me who need special help."},
{question:/**50.*/"I am mindful of ministering to others as I pray."},
{question:/**51.*/"I set aside time to read and study scripture in order to gain a deeper understanding of biblical truth. "},
{question:/**52.*/"I can often adjust my leadership style to bring out the best in others."},
{question:/**53.*/"I have the ability to help people who may seem undeserving or beyond help. "},
{question:/**54.*/"I am outspoken against cultural trends, teachings, or events that contradict biblical principles."},
{question:/**55.*/"I like to offer direction for the whole person — relationally, emotionally, and spiritually."},
{question:/**56.*/"I pay close attention to those who teach so I may teach others scripture."},
{question:/**57.*/"I choose the best course of action from among various alternatives."},
{question:/**58.*/"I am comfortable identifying and using resources needed to accomplish tasks. "},
{question:/**59.*/"I adapt well to different cultures and surroundings."},
{question:/**60.*/"I visualize something before I build it."},
{question:/**61.*/"I like to find new and different means of communicating biblical truths."},
{question:/**62.*/"I tend to see the right or wrong in particular situations."},
{question:/**63.*/"I reassure those who need to make a change in their faith, family, or life. "},
{question:/**64.*/"When presented with the opportunity, I invite others to church believing that they will develop a faith in Jesus Christ."},
// {question:/**65.*/"I believe there are circumstances where I cannot succeed without God's direct interaction."},
{question:/**66.*/"I am challenged to limit my lifestyle in order to give away a higher percentage of my income."},
{question:/**67.*/"I sense doing practical tasks has spiritual meaning."},
{question:/**68.*/"I like to comfort others by letting them know they are not alone."},
{question:/**69.*/"I pray with conviction knowing God answers prayer."},
{question:/**70.*/"I can sense that something is true."},
{question:/**71.*/"I set goals, administer to people, and use resources effectively to reach those goals."},
{question:/**72.*/"I have compassion for people in pain."},
{question:/**73.*/"I see most actions as right or wrong and feel the need to correct the wrong.      "},
{question:/**74.*/"I faithfully provide long-term support and concern for others."},
{question:/**75.*/"I like to take an orderly approach as I study the Bible so that I may teach others about the Bible."},
{question:/**76.*/"I can envision the outcome of an individual’s or a group's action.   "},
{question:/**77.*/"I enjoy helping organizations or groups become more effective."},
{question:/**78.*/"I tend to relate to others in culturally sensitive ways."},
{question:/**79.*/"I enjoy using my handcrafted gifts to honor God."},
{question:/**80.*/"I enjoy using my artistic abilities to convey God's message."},
{question:/**81.*/"Others affirm my instinct and perception."},
{question:/**82.*/"I look for opportunities to reinforce those questioning their faith."},
{question:/**83.*/"I am pleased when others can tell that I am a Christian or ask me about my faith."},
// {question:/**84.*/"I know God is constantly present in my life."},
{question:/**85.*/"It is important to me that my financial support and other resources make a real difference in the lives and ministries of God's people."},
{question:/**86.*/"I enjoy doing small things that need to be done and try to do them without being asked."},
{question:/**87.*/"I enjoy having people in my home."},
{question:/**88.*/"I naturally pray for someone when I hear about needy situations."},
{question:/**89.*/"I can sense some things about others without knowing how I knew these things."},
{question:/**90.*/"I find that I can influence others to perform to their best capability.                "},
{question:/**91.*/"I know every person is important to God."},
{question:/**92.*/"I like people who are honest and will speak the truth."},
{question:/**93.*/"I enjoy giving guidance and practical support to small groups of people.    "},
{question:/**94.*/"I can motivate others so they want to study and learn more about scripture."},
{question:/**95.*/"I can help others through trying times.     "},
{question:/**96.*/"I enjoy learning how organizations function."},
{question:/**97.*/"I enjoy starting new programs, models, activities, or groups."},
{question:/**98.*/"I am good at and enjoy working with my hands."},
{question:/**99.*/"I enjoy writing and knowing that others find my writings meaningful.    "},
{question:/**100.*/"I can identify preaching, teaching, or communication which is not consistent with basic theology and biblical understanding."},
{question:/**101.*/"I can help others to take steps toward their spiritual growth."},
{question:/**102.*/"It is comfortable for me to tell others what Christ has done for me."},
// {question:/**103.*/"I encourage others to trust God."},
{question:/**104.*/"I give generously because of my commitment to stewardship."},
{question:/**105.*/"I feel comfortable helping others so they can be more effective."},
{question:/**106.*/"It is important to me that others feel they belong."},
{question:/**107.*/"I feel joy when someone asks me to pray for them."},
{question:/**108.*/"I discover biblical truths when I read and study scripture that helps others to understand."},
{question:/**109.*/"When I have a vision, I am able to help others share in that vision."},
{question:/**110.*/"I enjoy bringing hope and joy to people living in trying times."},
{question:/**111.*/"I express my faith even in unpopular or difficult places.  "},
{question:/**112.*/"I enjoy sharing my faith in a way that enables others to strengthen theirs."},
{question:/**113.*/"I teach scripture in ways that others can easily grasp and apply to their lives."},
{question:/**114.*/"I enjoy sharing my scriptural knowledge in a practical and helpful way."},
{question:/**115.*/"I envision an event, foresee possible problems, and prepare backup plans."},
{question:/**116.*/"I am able to coordinate or oversee several church ministries or groups."},
{question:/**117.*/"I create and construct things that help the church."},
{question:/**118.*/"I need time to be alone so that I can reflect and develop my imagination."},
{question:/**119.*/"I can sense when evil forces are at work in a person or situation.                      "},
{question:/**120.*/"I can challenge and encourage others in a way that promotes spiritual growth."},
{question:/**121.*/"I am attentive to the opportunities to share my faith with others."},
// {question:/**122.*/"I take action in spite of opposition or lack of support when I sense what God wants me to do."},
{question:/**123.*/"I have been given an abundance of resources so that I may use them to do God's ministry."},
{question:/**124.*/"I am eager to use whatever skills I have so I may help wherever needed.  "},
{question:/**125.*/"I enjoy making people feel comfortable even when they are in an unfamiliar environment."},
{question:/**126.*/"I see definite results in response to my prayers."},
{question:/**127.*/"I confidently share my knowledge and intuitiveness with others."},
{question:/**128.*/"I can envision a specific direction and help others get there."},
{question:/**129.*/"I enjoy being helpful to people in needy situations."},
{question:/**130.*/"I feel responsible to expose sin and challenge people to change their lives."},
{question:/**131.*/"It is meaningful for me to help others as they embark on their spiritual journey."},
{question:/**132.*/"I enjoy teaching in ways that help people grow spiritually and personally."},
{question:/**133.*/"I can solve problems and help others who can't solve their problems easily."}
    ];
}