import './App.css';
import React from 'react';
import { Welcome } from './Welcome';
import { Question } from './Question';
import { QDB } from './Questions';
import { ThankYou } from './ThankYou';
import { AnswerKey } from './AnswerKey';
import giftslogo from './Spiritual-Gifts-Logo.svg';
import cotklogo from './COTK-Full-Logo-White-Large.png';
import { Bookmark, getArchiveFromURL } from './Bookmark';

export function getInitialView(): 'thankyou'|'bookmark'|'welcome' {
  const thankyou = window.location.pathname.includes('thankyou');
  const bookmark = window.location.pathname.includes('bookmark') && getArchiveFromURL() != null;
  return thankyou ? 'thankyou' : bookmark ? 'bookmark' : 'welcome';
}

export class App extends React.Component<{}, {
  view: 'welcome'|'question'|'thankyou'|'bookmark',
  questionIndex: number,
  form: Array<string|number>,
  name: string, email: string, telephone: string
}> {
  constructor(props: any){
    super(props);
    this.state = {
      view: getInitialView(),
      questionIndex: 0,
      form: [],
      name: '',
      email: '',
      telephone: ''
    }
  }
  submitAnswer(answer: number|string, callback?: () => void){
    if (this.state.questionIndex+1 === QDB.Questions.length){
      //final question!
      this.setState({
        form: this.state.form.concat([answer]), 
        questionIndex: this.state.questionIndex+1,
        view: 'thankyou'
      }, () => {
        this.submitForm(this.state.form);
        if (callback)
          callback();
      });
    } else {
      this.setState({
        form: this.state.form.concat([answer]), 
        questionIndex: this.state.questionIndex+1
      }, callback);
    }
  }
  submitForm(form: (string | number)[]) {
    var gifts: {[key: string]: number} = {};
    for (let i = 0; i < QDB.Questions.length; i++) {
      const answer = +form[i];
      const thisCategory = AnswerKey[i];
      if (gifts[thisCategory] == null)
        gifts[thisCategory] = 0;
      gifts[thisCategory] += answer;
    }
    var GiftAndScore: {giftName: string, score: number}[] = [];
    const InOrderGiftScores: number[] = [];
    Object.keys(gifts).forEach((gift) => {
      GiftAndScore.push({giftName: gift, score: gifts[gift]});
      InOrderGiftScores.push(gifts[gift]);
    });
    GiftAndScore.sort((a, b) => {
      return b.score - a.score;
    });
    GiftAndScore.forEach((g) => {
      console.log(`${g.giftName}: ${g.score}`);
    });
    var req = new XMLHttpRequest();
    req.open("POST", 
      "https://sgdforwarder.azurewebsites.net/api/SGAForward?name="+
      encodeURIComponent(
        this.state.name
        )+"&tel="+
      encodeURIComponent(
        this.state.telephone
        )+"&email="+
      encodeURIComponent(
        this.state.email
        )+"&b="+
      encodeURIComponent(
        JSON.stringify(InOrderGiftScores)
      )
    );
    req.setRequestHeader('x-functions-key', process.env.REACT_APP_FUNC_KEY ?? '');
    req.send(
      JSON.stringify(GiftAndScore)
    );
  }
  view(){
    switch(this.state.view){
      case 'question':
        return <Question
          questionIndex={this.state.questionIndex} 
          onNext={(a) => {this.submitAnswer(a)}}
          onPrev={() => {
            this.state.form.pop();
            this.setState({questionIndex: this.state.questionIndex-1, form: this.state.form})
          }}
          onRestart={() => this.setState({view: 'welcome', questionIndex:0, form: []})}
        ></Question>
      case 'thankyou':
        return <ThankYou></ThankYou>
      case 'bookmark':
        return <Bookmark></Bookmark>
      case 'welcome':
      default:
        return <Welcome 
          onBegin={() => this.setState({view: 'question'})}
          onNameChange={(name) => this.setState({name: name})}
          onTelChange={(str) => this.setState({telephone: str})}
          onEmailChange={(str) => this.setState({email: str})}
        ></Welcome>
    }
  }
  componentDidMount(){    
    window.onkeyup = (ev: KeyboardEvent) => {
      this.keybuffer.push(ev.key);
      if (this.keybuffer.length > 6)
        this.keybuffer.shift();
      if (this.keybuffer.join('') === 'finish'){
        this._debugSubmitAnswer();
      }
    };
  }
  private _debugSubmitAnswer = () =>{
    this.submitAnswer(1, () => {
      if (this.state.view === 'question')
        window.requestAnimationFrame(this._debugSubmitAnswer)
    });

  }
  private keybuffer: string[] = [];
  render() {
    const isBookmark = this.state.view === 'bookmark';
    return <div className={"App" + (isBookmark ? ' bookmark': '')}>
      <a className="logo-parent" href="/" onClick={(e) => { this.setState({view: 'welcome'}); e.preventDefault(); return false;}}>
        <img src={giftslogo} alt="Spiritual Gifts" className="logo"/>
      </a>
      {this.view()}
      <footer>
        <a href="http://www.churchoftheking.us/" target="blank">
          <img src={cotklogo} alt="church of the king lake charles" style={{height: '44px'}} />
        </a>
      </footer>
    </div>
  }
}

export default App;
