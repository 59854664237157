import React from "react";

export class RadioScaleAnswers extends React.Component<{
    scale: number[],
    labels?: {[scale:number]: string},
    selectedScale?: string|number,
    questionIndex: number,
    confirmAnswer: (scale: number) => void
}, {
}>{
    constructor(props: any){
        super(props);
        this.state = {
        }
    }
    render(){
        
        return <div className="radio-parent">
            {
                this.props.scale.map((a, i) => {
                    const prefix = this.props.questionIndex.toString();
                    return <label key={prefix+','+a} className="radio-label">
                            <input type="radio" value={a} name="answers"
                            className='answer-scale'
                                onClick={() => {
                                    this.props.confirmAnswer(a);
                                }}
                            />
                            <span className="radio-label-text">
                                {(this.props.labels && this.props.labels[a] != null) ? this.props.labels[a]: null}
                            </span>
                        </label>
                })
            }
        </div>
    }
}