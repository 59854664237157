
import React, { useState } from 'react';
import { AllGiftCategories } from './Questions';
import giftslogo from './Spiritual-Gifts-Dove.svg';

export function getAnswersFromURL(raw: string|null): {score: number, category: string}[]|undefined {
    if (raw == null)
        return undefined;
    try {
        if (raw.length > 0 && raw[0] === '%5B')
            raw = decodeURIComponent(raw);
        const parsed = JSON.parse(raw);

        if (Array.isArray(parsed) && parsed.length > 0 && typeof parsed[0] === 'number'){
            const all = (parsed as number[]).map((score, i) => {
                return {
                    score: score,
                    category: AllGiftCategories[i]
                }
            });
            all.sort((a, b) => b.score - a.score);
            return all;
        } else {
            return undefined;
        }
    } catch {
        return undefined;
    }
}

export function getArchiveFromURL(): {
    answers: {score: number, category: string}[],
    name: string
}|undefined {
    const url = new URL(window.location.href);
    const answers = getAnswersFromURL(url.searchParams.get("a"));
    const name = url.searchParams.get("n");

    if (answers && name){
        return {
            name: name,
            answers: answers
        };
    }
    return undefined;
}

export const Bookmark: React.FC = () => {
    const [archive] = useState(getArchiveFromURL());
    if (archive){
        return <div className="archive-view">
            <table className="archive-preamble-table">
                <tbody>
                    <tr className="welcome">
                        <td>
                            <img className="archive-logo" src={giftslogo} alt="church of the king spiritual gifts dove logo" />
                        </td>
                        <td>
                            <span className="preamble">
                                Given by the Holy Spirit<br/>expressly for
                            </span>
                            <strong className="name">{ archive.name }</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="archive-answer-table">
                <tbody>
                    <tr>
                        <th>Spiritual Gift</th>
                        <th>Assessment Result</th>
                    </tr>
                    {
                        archive.answers.map((x) => <tr>
                            <td>{x.category}</td>
                            <td>{x.score}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    }
    else return <div>
        <i>Cannot load data.</i>
    </div>
}