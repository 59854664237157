import React from 'react';

const telMatch = new RegExp(/.*\d{3}.*\d{4,}.*/);
const emailMatch = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
export class Welcome extends React.Component<{
    onBegin: () => void,
    onNameChange: (str: string) => void,
    onTelChange: (str: string) => void,
    onEmailChange: (str: string) => void
}, {
  hasName: boolean,
  telBlurred: boolean,
  telHasSevenDigits: boolean,
  emailIsValid: boolean,
  emailBlurred: boolean,
}> {
  constructor(props: any){
    super(props);
    this.state = {
      telHasSevenDigits: false,
      emailIsValid: false,
      hasName: false,
      emailBlurred: false,
      telBlurred: false
    }
  }
  render() {
    return <div className="view mobile-flex-v">
      <div className="embed-container video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/vJbiLLuT8Rs" frameBorder={0} allowFullScreen={true}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Instructional video"
        ></iframe>
      </div>
      <div className="tablet-mrg-t-10 name-parent mobile-flex-g-1">
        <form className="name-form flex-v ">
          <div className="flex-h mobile-v flex-justify-between">
            <input type="text" name="name" id="name" placeholder="Your name" className="tablet-w-100" onChange={(e) => {
              this.props.onEmailChange(e.target.value);
              this.setState({hasName: e.target.value != null && e.target.value.length > 0})
            }}/>
          </div>
          <div className="flex-h mobile-v flex-justify-between">
            <input type="text" name="telephone" id="telephone" placeholder="Your telephone #" onChange={(e) => {
              this.props.onTelChange(e.target.value);
              this.setState({telHasSevenDigits: e.target.value != null && telMatch.test(e.target.value)})
            }} onBlur={() => this.setState({telBlurred: true})}/>
            <input type="text" name="email" id="email" placeholder="Your email" onChange={(e) => {
              this.props.onNameChange(e.target.value);
              this.setState({emailIsValid: e.target.value != null && emailMatch.test(e.target.value)})
            }} onBlur={() => this.setState({emailBlurred: true})}/>
          </div>
          <div className="flex-h mobile-v flex-justify-between">
            <div className="spacer">
              {this.state.emailBlurred&&!this.state.emailIsValid ? <div className="invalid">Email is not valid</div> : null}
              {this.state.telBlurred&&!this.state.telHasSevenDigits ? <div className="invalid">Phone is not valid</div>: null}
            </div>
            <button type="submit" name="begin" className="primary mobile-mrg-t-10"
              onClick={() => this.props.onBegin()}
              disabled={!this.state.hasName || !this.state.emailIsValid || !this.state.telHasSevenDigits}
            >
              Begin Application
            </button>
          </div>
        </form>
      </div>
    </div>
  }
}
