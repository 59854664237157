import React from "react";
import giftslogo from './Spiritual-Gifts-Dove.svg';

export class ThankYou extends React.Component{
    render(){
        return <div className="view mobile-flex-v">
            <p className="mobile-flex-g-1 pad-lr-8">
                Thank you for completing the assessment! One of our team members will review your results and will contact you directly.
            </p>
            <div className="mobile-flex-g-1">
                <div className="thank-you circle-img">
                    <img src={giftslogo} alt="Spiritual Gifts"/>
                </div>
            </div>
            <p className="mobile-flex-g-1">
                <b>Thank you</b> for donating your time.
            </p>
        </div>
    }
}