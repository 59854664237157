import React from "react";

export class Countdown extends React.Component<{
    durationMS: number,
    elapsedMS: number
}, {
}>{
    render(){
        return <div className="countdown">
            {
                ((this.props.durationMS - this.props.elapsedMS)/1000).toFixed(0)
            }s remaining
            <span className="countdown-fill"
                style={
                    {
                        width: (this.props.elapsedMS / this.props.durationMS * 100) + '%'
                    }
                }
            ></span>
        </div>
    }
}